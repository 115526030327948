import {EventEmitter, Injectable} from '@angular/core';
import {UserManagementService} from './user-management.service';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {CustomerModule} from '../classes/customer-module';
import {Conveyor} from '../classes/conveyor';
import {MockConveyors} from '../classes/mock/mock-conveyors';
import {ModuleDefinition} from '../classes/module-definition';
import {HttpClient} from '@angular/common/http';
import {ComponentTypes} from '../classes/DMS_Parser/component-types';
import {Feature} from '../classes/DMS_Parser/feature';
import {ModuleComponent} from '../classes/module-component';
import {FeatureTypes} from '../classes/DMS_Parser/feature-types';
import {Variables} from '../classes/DMS_Parser/variables';
import {VariableTypes} from '../classes/DMS_Parser/variable-types';
import {ChildVaribles} from '../classes/DMS_Parser/child-varibles';
import {ComponentFeature} from '../classes/component-feature';
import {ComponentVariable} from '../classes/component-variable';
import {VariableStates} from '../classes/variable-states';
import {ModuleConnections} from '../classes/enums/module-connections.enum';
import {ConveyorBeltInfo} from '../classes/conveyor-belt-info';
import {LanguageEntry} from '../classes/DMS_Parser/language-entry';
import {ModuleRule} from '../classes/module-rule';
import {ScreenLinings} from '../classes/mock/screen-linings';
import {BluectrlApiService} from './bluectrl-api.service';
import {Wards} from '../classes/wards';
import {SetupTask} from '../classes/setup-task';
import {SetupTaskParameter} from '../classes/setup-task-parameter';
import {DatabaseService} from './database.service';
import {ConnectivityService} from './connectivity.service';
import {ConveyorTypes} from '../classes/conveyor-types';

@Injectable({
  providedIn: 'root'
})
export class ServerCommunicationService {

  public Modules: ModuleDefinition[];
  public Loaded = false;
  public OnModulesLoaded = new EventEmitter();
  public OnModulesLoadedError = new EventEmitter();
  public CustomerModulesLoadedError = new EventEmitter();
  public CustomerModules: CustomerModule[];
  public dmsVersion = 'NONE';
  public currentDMS: any;

  public ConveyorTypes: ConveyorTypes[];

  constructor(private usermanagement: UserManagementService,
              private httpClient: HttpClient,
              private connectivity: ConnectivityService,
              private apiService: BluectrlApiService,
              private database: DatabaseService) {

    this.Modules = [];

  }

  static GetComponentList(json: any): ComponentTypes[] {
    const componenttypes: ComponentTypes[] = [];

    if (json.ComponentTypes) {
      for (const k of Object.keys(json.ComponentTypes)) {
        if (json.ComponentTypes[k]) {
          const tp = json.ComponentTypes[k];

          const cmp = new ComponentTypes();
          cmp.Key = k;

          console.log(k);

          if (tp.Default.Name) {
            cmp.Name = tp.Default.Name;
          }

          if (tp.Default.Virtual === true) {
            cmp.Virtual = tp.Default.Virtual;
          }

          if (tp.Features) {
            for (const fk of Object.keys(tp.Features)) {
              if (tp.Features[fk]) {
                const ft = tp.Features[fk];
                const feature = new Feature();
                feature.Name = fk;
                if (ft.Required) {
                  feature.Required = ft.Required;
                }
                if (ft.Virtual) {
                  feature.Virtual = ft.Virtual;
                }

                if (ft.Visible !== null && ft.Visible !== undefined) {
                  feature.Visible = ft.Visible;
                }

                if(cmp.Key === 'BC-AJI01-003') {
                  console.log(ft.Parameters);
                }

                if (ft.Parameters) {
                  for (const ftkey of Object.keys(ft.Parameters)) {
                    if (ftkey.indexOf('MaximumValue') >= 0) {
                      feature.MaxValue = ft.Parameters[ftkey];
                    } else if (ftkey.indexOf('MinimumValue') >= 0) {
                      feature.MinValue = ft.Parameters[ftkey];
                    } else if (ftkey.indexOf('DefaultValue') >= 0) {
                      feature.DefaultValue = ft.Parameters[ftkey];
                    } else if (ftkey.indexOf('StepSize') >= 0) {
                      feature.StepSize = ft.Parameters[ftkey];
                    } else if (ftkey.indexOf('ShowPositivOnly') >= 0) {
                      feature.ShowPositiveOnly = ft.Parameters[ftkey];
                    } else if (ftkey.indexOf('DirectionDependency') >= 0) {

                      feature.DirectionDependency = ft.Parameters[ftkey];
                    } else if (ftkey.indexOf('ConstellationDependant') >= 0) {
                      feature.ConstellationDependency = ft.Parameters[ftkey];
                    }
                  }
                }
                cmp.Features.push(feature);

              }
            }
          }

          componenttypes.push(cmp);

        }
      }
    }


    return componenttypes;
  }

  static GetVaribleList(json: any): VariableTypes[] {
    const componenttypes: VariableTypes[] = [];

    if (json.VariableTypes) {
      for (const k of Object.keys(json.VariableTypes)) {
        if (json.VariableTypes[k]) {
          const tp = json.VariableTypes[k];

          const np = new VariableTypes(k);

          if (tp.VariantType) {
            np.VariantType = tp.VariantType;
          }
          if (tp.ObjectType) {
            np.ObjectType = tp.ObjectType;
          }
          if (tp.ContentName) {
            np.ContentName = tp.ContentName;
          }
          if (tp.ContentType) {
            np.ContentType = tp.ContentType;
          }
          if (tp.Length) {
            np.Length = tp.Length;
          }
          if (tp.ValueDependency) {
            np.ValueDependency = tp.ValueDependency;
          }

          if (tp.DisplayPath) {
            np.DisplayPath = tp.DisplayPath;
          }
          if (tp.ValuePath) {
            np.ValuePath = tp.ValuePath;
          }

          if (tp.States) {
            const sts = [];

            for (const s of tp.States) {
              sts.push(s);
            }

            np.States = sts;
          }

          if (tp.ChildVariables) {

            const children: ChildVaribles[] = [];

            for (const ks of Object.keys(tp.ChildVariables)) {
              if (tp.ChildVariables[ks]) {
                const child = new ChildVaribles();
                child.Key = ks;
                child.Name = tp.ChildVariables[ks];
                children.push(child);
              }
            }

            np.Children = children;

          }
          componenttypes.push(np);
        }
      }
    }


    return componenttypes;
  }

  static GetRuleList(json: any): any[] {
    const ruletypes: any[] = [];

    if (json.ModuleRules) {

      for (const k of Object.keys(json.ModuleRules)) {

        if (json.ModuleRules[k]) {
          const tp = json.ModuleRules[k];

          const np = {
            RuleType: k,
            Action: null,
            Inputs: []
          };

          if (tp.Action) {
            np.Action = tp.Action;
          }
          if (tp.Inputs) {
            np.Inputs = tp.Inputs;
          }


          ruletypes.push(np);
        }
      }
    }


    return ruletypes;
  }

  static GetFeatureList(json: any): FeatureTypes[] {
    const componenttypes: FeatureTypes[] = [];

    if (json.FeatureTypes) {
      for (const k of Object.keys(json.FeatureTypes)) {
        if (json.FeatureTypes[k]) {
          const tp = json.FeatureTypes[k];

          const ftp = new FeatureTypes(k);
          if (tp.Variables) {
            for (const fk of Object.keys(tp.Variables)) {
              if (tp.Variables[fk]) {

                const uaPath = fk;
                const vari = tp.Variables[fk]; // "gModule.Devices.{ComponentName}.{Context}":
                ftp.Context = uaPath;
                for (const fks of Object.keys(vari)) {

                  if (vari[fks]) { // "ACTIVE"
                    const name = fks;
                    const varible = vari[fks];
                    let context: string;
                    let typ: string;
                    let unit: string;
                    let stp: number;
                    let mode: string;
                    let designRel = true;
                    let download = false;
                    let upload = false;

                    if (varible.StepSize) {
                      stp = varible.StepSize;
                    }

                    if (varible.DownloadPossible) {
                      download = varible.DownloadPossible;
                    }

                    if (varible.UploadPossible) {
                      upload = varible.UploadPossible;
                    }

                    if (varible.Mode) {
                      mode = varible.Mode;
                    }

                    if (varible.DisplayUnitID) {
                      unit = varible.DisplayUnitID;
                    }

                    if (varible.VariableType) {
                      typ = varible.VariableType;
                    }

                    if (varible.Context) {
                      context = varible.Context;
                    }

                    if (varible.RequiredInDesign !== null && varible.RequiredInDesign !== undefined) {
                      designRel = varible.RequiredInDesign;
                    }

                    const result = new Variables(name, context, typ, unit, stp, uaPath, mode);
                    result.DownloadPossible = download;
                    result.UploadPossible = upload;
                    result.RequiredInDesign = designRel;


                    if (varible.ConstellationDependant) {
                      result.ConstellationDependency = varible.ConstellationDependant;
                    }

                    ftp.AddNewVariable(result);

                  }
                }
              }
            }
          }
          componenttypes.push(ftp);
        }
      }
    }
    return componenttypes;
  }

  public GetModules(): ModuleDefinition[] {
    return this.Modules;
  }

  public LoadDMS() {

    if (this.connectivity.inOfflineMode) {
      if (this.database.latestDMS) {
        // GET LAST FROM DB
        const dbDms = this.database.latestDMS;
        this.dmsVersion = dbDms.Info.Version;
        this.LoadModules(dbDms);
        this.currentDMS = dbDms;

      } else {
        this.httpClient.get('/assets/dms/bluectrl-spec-gen-latest.json').subscribe(data => {
          const json_str = JSON.stringify(data);
          const json = JSON.parse(json_str);

          if (json.Info) {
            this.dmsVersion = json.Info.Version;
          }

          this.LoadModules(json, true);
          this.currentDMS = json;
        }, error => {
          console.log(error);
          this.OnModulesLoadedError.emit();
        });
      }
    } else {
      this.apiService.GetDms().subscribe((dms: any) => {
          if (dms.Info) {
            this.dmsVersion = dms.Info.Version;
          }
          this.LoadModules(dms);
          this.currentDMS = dms;
          this.database.AddNewDMS(dms);

        }, () => {

          if (this.database.latestDMS) {
            // GET LAST FROM DB
            const dbDms = this.database.latestDMS;
            this.dmsVersion = dbDms.Info.Version;
            this.LoadModules(dbDms);
            this.currentDMS = dbDms;

          } else {
            this.httpClient.get('/assets/dms/bluectrl-spec-gen-latest.json').subscribe(data => {
              const json_str = JSON.stringify(data);
              const json = JSON.parse(json_str);

              if (json.Info) {
                this.dmsVersion = json.Info.Version;
              }

              this.LoadModules(json, true);
              this.currentDMS = json;
            }, error => {
              console.log(error);
              this.OnModulesLoadedError.emit();
            });
          }
        }
      );
    }
  }

  private async LoadConveyorTypes() {
    this.ConveyorTypes = [];

    this.httpClient.get('assets/dms/conveyorTypes.json').subscribe(file => {
      const types_str = JSON.stringify(file);
      const convTypes = JSON.parse(types_str);

      if (convTypes) {
        for (const k of Object.keys(convTypes)) {
          if (convTypes[k]) {
            const tps = convTypes[k];

            if (tps.name && tps.typeOfAddOn != undefined) {
              this.ConveyorTypes.push(new ConveyorTypes(k, tps.name, tps.typeOfAddOn));

            }
          }
        }
        return;
      }

    });
  }

  public async LoadModules(dms: any, lokal = false) {

    try {
      const json = dms;

      const componenttypes = ServerCommunicationService.GetComponentList(json);
      const featuretypes = ServerCommunicationService.GetFeatureList(json);
      const variableTypes = ServerCommunicationService.GetVaribleList(json);
      const ruleTypes = ServerCommunicationService.GetRuleList(json);

      // LOAD CONVEYOR Types
      await this.LoadConveyorTypes();

      if (json.ModuleTypes) {
        for (const k of Object.keys(json.ModuleTypes)) {
          if (json.ModuleTypes[k]) {
            const vl = json.ModuleTypes[k];


            const md = new ModuleDefinition(vl.Product.Group, vl.Product.Line, vl.Product.Name, vl.Product.Type, vl.Product.Version, k);

            if(md.Key === 'B0703-002') {
              console.log(vl);
            }

            if (vl.Product.InitialModuleOnly) {
              md.IsInitialModuleOnly = vl.Product.InitialModuleOnly;
            }

            if (vl.Product.Manufacturer) {
              md.Manufacturer = vl.Product.Manufacturer;
            }

            if (vl.Product.Model) {
              md.Model = vl.Product.Model;
            }

            if (vl.Options) {
              if (vl.Options.AirExtractionPossible) {
                md.AirExtractionPossible = true;
              }
              if (vl.Options.SupportModule) {
                md.SupportModule = true;
              }
              if (vl.Options.MaximumConnections) {
                md.MaximumConnections = vl.Options.MaximumConnections;
              }
            }


            if (vl.Components) {
              for (const kc of Object.keys(vl.Components)) {
                if (vl.Components[kc]) {
                  const key = kc;
                  const typ = vl.Components[kc];

                  const comp = componenttypes.find(ex => ex.Key === typ);

                  if(md.Key === 'B0703-002') {
                    console.log(typ);
                    console.log(comp);
                  }

                  if (comp) {
                    const mdc = new ModuleComponent(key, comp.Name, typ, md.Type);
                    mdc.Virtual = comp.Virtual;

                    if (comp.Features) {
                      for (const f of comp.Features) {


                        const ftr = featuretypes.find(ex => ex.Name === f.Name);
                        if (ftr) {
                          mdc.AddFeature(new ComponentFeature(f.Name, f.Required, ftr.Context));

                          for (const v of ftr.Variable) {
                            if (v.Context && !f.Name.startsWith('Service') &&
                              (v.Context.toUpperCase() === 'WRITE' || v.Context.toUpperCase() === 'BOTH')) {
                              if (v.VariableType) {
                                const vartype = variableTypes.find(ex => ex.Name === v.VariableType);

                                if (vartype) {
                                  const vr = new ComponentVariable(v.Name, v.Name, mdc.Type, v.Context, md.Type, v.UAPath);
                                  if (f.MaxValue !== null && f.MaxValue !== undefined) {
                                    vr.MaxValue = f.MaxValue;
                                  }
                                  if (f.MinValue !== null && f.MinValue !== undefined) {
                                    vr.MinValue = f.MinValue;
                                  }
                                  if(md.Key === 'B0602-002') {
                                    console.log(f);
                                  }
                                  if (f.DefaultValue !== null && f.DefaultValue !== undefined) {
                                    vr.DefaultValue = f.DefaultValue;
                                  }

                                  if (f.ShowPositiveOnly === true) {
                                    vr.ShowPositiveOnly = true;
                                    if ((vr.DefaultValue && vr.DefaultValue < 0) || (vr.MinValue < 0 && vr.MaxValue < 0)) {
                                      vr.Direction = -1;
                                      vr.DefaultValue = vr.DefaultValue * vr.Direction;
                                      const mx = vr.MinValue * vr.Direction;
                                      const mn = vr.MaxValue * vr.Direction;
                                      if (mx < mn) {
                                        vr.MaxValue = mn;
                                        vr.MinValue = mx;
                                      }
                                    }

                                  }

                                  if (f.StepSize) {
                                    vr.StepSize = f.StepSize;
                                  } else if (v.StepSize) {
                                    vr.StepSize = v.StepSize;
                                  }
                                  if (v.Mode) {
                                    vr.Mode = v.Mode;
                                  }

                                  if (v.UnitString) {
                                    vr.Unit = v.UnitString;
                                  }

                                  if (v.ConstellationDependency) {
                                    vr.ConstallationDependency = v.ConstellationDependency;
                                  }

                                  if (f.DirectionDependency) {
                                    vr.DirectionDependency = f.DirectionDependency;
                                  }

                                  if (v.RequiredInDesign !== null && v.RequiredInDesign !== undefined) {
                                    vr.DesignRelevant = v.RequiredInDesign;
                                  }

                                  if (vartype.ValuePath) {
                                    vr.ValuePath = vartype.ValuePath;
                                  }
                                  if (vartype.DisplayPath) {
                                    vr.DisplayPath = vartype.DisplayPath;
                                  }


                                  vr.Virtual = f.Virtual;
                                  vr.Visible = f.Visible;
                                  vr.FeatureName = f.Name;
                                  vr.Typ = vartype.VariantType;
                                  vr.VariableType = vartype.Name;
                                  vr.UploadPossible = v.UploadPossible;
                                  vr.DownloadPossible = v.DownloadPossible;

                                  if (vr.VariableType === 'BooleanSwitch') {
                                    vr.States.push(new VariableStates(true, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                    vr.States.push(new VariableStates(false, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                  } else if (vr.VariableType === 'ScreenLining') {

                                    const mdPanels = ScreenLinings.find(ex => ex.module === md.Type + '-' + md.Version &&
                                      ex.component === mdc.PlcKey &&
                                      ex.parameter === vr.Name);

                                    if (mdPanels) {
                                      for (const p of mdPanels.linings) {
                                        const st = new VariableStates(p.id, mdc.Type, md.Type + '-' + md.Version, v.Name);
                                        st.DefaultText = p.name;
                                        vr.States.push(st);
                                      }
                                    }


                                  }


                                  if (vartype.States) {
                                    for (const st of vartype.States) {
                                      vr.States.push(new VariableStates(st, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                    }
                                  }


                                  mdc.AddConfigParameter(vr);

                                }
                              }
                            }

                            if (v.Context && f.Name.startsWith('Service') &&
                              (v.Context.toUpperCase() === 'WRITE' || v.Context.toUpperCase() === 'BOTH')) {
                              if (v.VariableType) {
                                const vartype = variableTypes.find(ex => ex.Name === v.VariableType);

                                if (vartype) {
                                  const vr = new ComponentVariable(v.Name, v.Name, mdc.Type, v.Context, md.Type, v.UAPath);
                                  if (f.MaxValue !== null && f.MaxValue !== undefined) {
                                    vr.MaxValue = f.MaxValue;
                                  }
                                  if (f.MinValue !== null && f.MinValue !== undefined) {
                                    vr.MinValue = f.MinValue;
                                  }
                                  if (f.DefaultValue !== null && f.DefaultValue !== undefined) {
                                    vr.DefaultValue = f.DefaultValue;
                                  }

                                  if (f.ShowPositiveOnly === true) {
                                    vr.ShowPositiveOnly = true;
                                    if ((vr.DefaultValue && vr.DefaultValue < 0) || (vr.MinValue < 0 && vr.MaxValue < 0)) {
                                      vr.RecipeDirection = -1;
                                      vr.DefaultValue = vr.DefaultValue * vr.RecipeDirection;
                                      const mx = vr.MinValue * vr.RecipeDirection;
                                      const mn = vr.MaxValue * vr.RecipeDirection;
                                      if (mx < mn) {
                                        vr.MaxValue = mn;
                                        vr.MinValue = mx;
                                      }
                                    }

                                  }

                                  if (f.StepSize) {
                                    vr.StepSize = f.StepSize;
                                  } else if (v.StepSize) {
                                    vr.StepSize = v.StepSize;
                                  }
                                  if (v.Mode) {
                                    vr.Mode = v.Mode;
                                  }

                                  if (v.UnitString) {
                                    vr.Unit = v.UnitString;
                                  }

                                  if (v.ConstellationDependency) {
                                    vr.ConstallationDependency = v.ConstellationDependency;
                                  }

                                  if (f.DirectionDependency) {
                                    vr.DirectionDependency = f.DirectionDependency;
                                  }

                                  if (v.RequiredInDesign !== null && v.RequiredInDesign !== undefined) {
                                    vr.DesignRelevant = v.RequiredInDesign;
                                  }

                                  if (vartype.ValuePath) {
                                    vr.ValuePath = vartype.ValuePath;
                                  }
                                  if (vartype.DisplayPath) {
                                    vr.DisplayPath = vartype.DisplayPath;
                                  }
                                  vr.Virtual = f.Virtual;
                                  vr.Visible = f.Visible;
                                  vr.FeatureName = f.Name;
                                  vr.Typ = vartype.VariantType;
                                  vr.VariableType = vartype.Name;
                                  vr.UploadPossible = v.UploadPossible;
                                  vr.DownloadPossible = v.DownloadPossible;

                                  if (vr.VariableType === 'BooleanSwitch') {
                                    vr.States.push(new VariableStates(true, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                    vr.States.push(new VariableStates(false, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                  } else if (vr.VariableType === 'ScreenLining') {

                                    const mdPanels = ScreenLinings.find(ex => ex.module === md.Type + '-' + md.Version &&
                                      ex.component === mdc.PlcKey &&
                                      ex.parameter === vr.Name);

                                    if (mdPanels) {
                                      for (const p of mdPanels.linings) {
                                        const st = new VariableStates(p.id, mdc.Type, md.Type + '-' + md.Version, v.Name);
                                        st.DefaultText = p.name;
                                        vr.States.push(st);
                                      }
                                    }


                                  }

                                  if (vartype.States) {
                                    for (const st of vartype.States) {
                                      vr.States.push(new VariableStates(st, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                    }
                                  }

                                  if (vr.Name === 'ServiceActive') {
                                    vr.CurrentValue = true;
                                  }

                                  mdc.AddMaintenanceParameter(vr);

                                }
                              }
                            }

                            if (f.Name === 'MonitorModuleErrors') {
                              // if (v.Name === 'Error') {
                              //   const vartype = variableTypes.find(ex => ex.Name === v.VariableType);
                              //
                              //   if (vartype) {
                              //     if (vartype.Length > 0) {
                              //       for (let i = 0; i < vartype.Length; i++) {
                              //         const nt = new NotificationEntry(md.Type + '-' + md.Version, mdc.PlcKey, i, 'Error');
                              //         nt.ComponentTranslateId = mdc.GetTranslationId();
                              //         mdc.Errors.push(nt);
                              //       }
                              //     }
                              //   }
                              // }
                            } else if (f.Name === 'ComponentNotification' || f.Name === 'MonitorModuleWarnings' || f.Name === 'ComponentNotificationExtended') {
                              // if (v.Name === 'Warning') {
                              //   const vartype = variableTypes.find(ex => ex.Name === v.VariableType);
                              //
                              //   if (vartype) {
                              //     if (vartype.Length > 0) {
                              //       // for (let i = 0; i < vartype.Length; i++) {
                              //       //   const nt = new NotificationEntry(md.Type + '-' + md.Version, mdc.PlcKey, i, 'Warning');
                              //       //   nt.ComponentTranslateId = mdc.GetTranslationId();
                              //       //   mdc.Warnings.push(nt);
                              //       // }
                              //     }
                              //   }
                              // } else if (v.Name === 'Error') {
                              //   const vartype = variableTypes.find(ex => ex.Name === v.VariableType);
                              //
                              //   if (vartype) {
                              //     if (vartype.Length > 0) {
                              //       for (let i = 0; i < vartype.Length; i++) {
                              //         const nt = new NotificationEntry(md.Type + '-' + md.Version, mdc.PlcKey, i, 'Error');
                              //         nt.ComponentTranslateId = mdc.GetTranslationId();
                              //         mdc.Errors.push(nt);
                              //       }
                              //     }
                              //   }
                              // }


                            } else if ((v.Context && !f.Name.startsWith('Service') && (v.Context.toUpperCase() === 'READ' ||
                                v.Context.toUpperCase() === 'BOTH' ||
                                v.UAPath.indexOf('Monitor') >= 0)) ||
                              f.Name.indexOf('Monitor') >= 0) {
                              if (v.Name !== 'ConverterAlarmWord' && v.Name !== 'ConverterWarningWord') {

                                if (v.Name === 'FaultCodeDrive') {
                                  const vartype = variableTypes.find(ex => ex.Name === v.VariableType);
                                  if (vartype) {
                                    const vr = new ComponentVariable(v.Name, v.Name, mdc.Type, v.Context, md.Type, v.UAPath);
                                    vr.Virtual = f.Virtual;
                                    vr.Visible = f.Visible;
                                    vr.FeatureName = f.Name;
                                    vr.Typ = vartype.VariantType;
                                    vr.VariableType = vartype.Name;
                                    vr.UploadPossible = v.UploadPossible;
                                    vr.DownloadPossible = v.DownloadPossible;

                                    for (let i = 0; i <= 209; i++) {
                                      const vs = new VariableStates(i, mdc.Type, md.Type + '-' + md.Version, v.Name);
                                      vs.TranslationId = 'MODULES.FAULTCODEDRIVE.' + i.toString();
                                      vr.States.push(vs);
                                    }
                                    mdc.Monitorings.push(vr);
                                  }
                                } else {
                                  if (v.VariableType) {
                                    const vartype = variableTypes.find(ex => ex.Name === v.VariableType);

                                    if (vartype) {
                                      const vr = new ComponentVariable(v.Name, v.Name, mdc.Type, v.Context, md.Type, v.UAPath);
                                      /*
                                      if (f.MaxValue) {
                                        vr.MaxValue = f.MaxValue;
                                      }
                                      if (f.MinValue) {
                                        vr.MinValue = f.MinValue;
                                      }
                                      if (f.DefaultValue) {
                                        vr.DefaultValue = f.DefaultValue;
                                      }
                                      if (v.StepSize) {
                                        vr.StepSize = v.StepSize;
                                      }
                                      */
                                      if (v.UnitString) {
                                        vr.Unit = v.UnitString;
                                      }

                                      if (vartype.ValuePath) {
                                        vr.ValuePath = vartype.ValuePath;
                                      }
                                      if (vartype.DisplayPath) {
                                        vr.DisplayPath = vartype.DisplayPath;
                                      }

                                      if (f.ConstellationDependency) {
                                        vr.ConstallationDependency = f.ConstellationDependency;
                                      }

                                      if (f.DirectionDependency) {
                                        vr.DirectionDependency = f.DirectionDependency;
                                      }
                                      vr.Virtual = f.Virtual;
                                      vr.Visible = f.Visible;
                                      vr.FeatureName = f.Name;
                                      vr.Typ = vartype.VariantType;
                                      vr.VariableType = vartype.Name;
                                      vr.UploadPossible = v.UploadPossible;
                                      vr.DownloadPossible = v.DownloadPossible;


                                      if (vr.VariableType === 'BooleanSwitch') {
                                        vr.States.push(new VariableStates(true, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                        vr.States.push(new VariableStates(false, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                      } else if (vr.VariableType === 'ScreenLining') {

                                        const mdPanels = ScreenLinings.find(ex => ex.module === md.Type + '-' + md.Version &&
                                          ex.component === mdc.PlcKey &&
                                          ex.parameter === vr.Name);

                                        if (mdPanels) {
                                          for (const p of mdPanels.linings) {
                                            const st = new VariableStates(p.id, mdc.Type, md.Type + '-' + md.Version, v.Name);
                                            st.DefaultText = p.name;
                                            vr.States.push(st);
                                          }
                                        }
                                      }

                                      if (vartype.States) {
                                        for (const st of vartype.States) {
                                          vr.States.push(new VariableStates(st, mdc.Type, md.Type + '-' + md.Version, v.Name));
                                        }
                                      }
                                      mdc.Monitorings.push(vr);

                                    }
                                  }
                                }


                              }
                            }

                          }
                        }
                      }
                    }
                    md.AddComponent(mdc);
                  }

                }
              }
            }

            if (vl.Rules) {
              // Oh we have module rules
              for (const mr of Object.keys(vl.Rules)) {
                if (vl.Rules[mr]) {
                  const key = mr;
                  const rule = vl.Rules[mr];

                  if (rule.RuleType) {
                    const tpe = ruleTypes.find(ex => ex.RuleType === rule.RuleType);
                    if (tpe) {
                      const rl: ModuleRule = new ModuleRule();
                      rl.RuleName = key;
                      rl.RuleType = tpe.RuleType;
                      rl.ActionName = tpe.Action;
                      rl.Inputs = tpe.Inputs;
                      rl.Mode = rule.Mode;
                      rl.Silent = rule.Silent;

                      if (rule.Condition) {
                        const condi: string = rule.Condition;

                        const split = condi.split('.');
                        if (split.length > 0) {
                          // Component and Variable
                          rl.TriggerComponent = split[0];

                          if (split.length > 1) {
                            if (split[1].indexOf('==') >= 0) {
                              // Have component and value
                              const parspl = split[1].split('==');

                              if (parspl.length > 0) {
                                rl.TriggerParameter = parspl[0];
                                if (parspl.length > 1) {
                                  rl.TriggerValue = parspl[1];

                                }
                              }

                            } else {
                              rl.TriggerParameter = split[1];
                            }
                          }

                        } else {
                          // ONLY COMPONENT
                          rl.TriggerComponent = split[0];
                        }

                      }

                      if (rule.Target) {
                        const condi: string = rule.Target;

                        const split = condi.split('.');
                        if (split.length > 0) {
                          // Component and Variable
                          rl.TargetComponent = split[0];

                          if (split.length > 1) {
                            if (split[1].indexOf('==') >= 0) {
                              // Have component and value
                              const parspl = split[1].split('==');

                              if (parspl.length > 0) {
                                rl.TargetParameter = parspl[0];
                                if (parspl.length > 1) {
                                  rl.TargetValue = parspl[1];
                                }
                              }

                            } else if (split[1].indexOf('=') >= 0) {
                              const parspl = split[1].split('=');

                              if (parspl.length > 0) {
                                rl.TargetParameter = parspl[0];
                                if (parspl.length > 1) {
                                  rl.TargetValue = parspl[1];
                                }
                              }
                            } else {
                              rl.TargetParameter = split[1];
                            }
                          }

                        } else {
                          // ONLY COMPONENT
                          rl.TargetComponent = split[0];
                        }

                      } else if (rule.Action) {
                        const condi: string = rule.Action;

                        const split = condi.split('.');
                        if (split.length > 0) {
                          // Component and Variable
                          rl.TargetComponent = split[0];

                          if (split.length > 1) {
                            if (split[1].indexOf('==') >= 0) {
                              // Have component and value
                              const parspl = split[1].split('==');

                              if (parspl.length > 0) {
                                rl.TargetParameter = parspl[0];
                                if (parspl.length > 1) {
                                  rl.TargetValue = parspl[1];
                                }
                              }

                            } else if (split[1].indexOf('=') >= 0) {
                              const parspl = split[1].split('=');

                              if (parspl.length > 0) {
                                rl.TargetParameter = parspl[0];
                                if (parspl.length > 1) {
                                  rl.TargetValue = parspl[1];
                                }
                              }
                            } else {
                              rl.TargetParameter = split[1];
                            }
                          }

                        } else {
                          // ONLY COMPONENT
                          rl.TargetComponent = split[0];
                        }

                      }

                      md.ModuleRules.push(rl);

                    }
                  }

                }
              }
            }
            this.Modules.push(md);
          }
        }
      }

      this.httpClient.get('/assets/dms/defaulttasks.json').subscribe((tasks: any[]) => {

        for (const mdl of this.Modules) {
          for (const defaultTask of tasks) {
            const task = new SetupTask();
            task.Code = defaultTask.task.id;
            task.DefaultText = defaultTask.task.text;
            task.CodeText = defaultTask.task.codeText;
            task.Text = defaultTask.task.text;
            task.DefaultTask = true;

            if (defaultTask.task.prefix) {
              const prefix = defaultTask.task.prefix;
              const mdprefix = new SetupTaskParameter();
              mdprefix.component = prefix.component;
              mdprefix.parameter = prefix.parameter;
              task.Prefix = mdprefix;
            }
            if (defaultTask.task.surfix) {
              const surfix = defaultTask.task.surfix;
              const mdsurfix = new SetupTaskParameter();
              mdsurfix.component = surfix.component;
              mdsurfix.parameter = surfix.parameter;
              task.Surfix = mdsurfix;
            }
            mdl.SetupTasks.push(task);


          }
        }

        this.httpClient.get('assets/dms/addtionalModuleInfo.json').subscribe(file => {
          const addInfo_str = JSON.stringify(file);
          const addInfo = JSON.parse(addInfo_str);


          if (addInfo) {
            for (const k of Object.keys(addInfo)) {
              if (addInfo[k]) {
                const nfo = addInfo[k];
                const mdl = this.Modules.find(ex => ex.Type + '-' + ex.Version === k);
                if (mdl) {
                  if (nfo.Outputs) {
                    for (const out of nfo.Outputs) {
                      const cbInfo = new ConveyorBeltInfo();
                      cbInfo.ConnectionPoint = this.StringToConnectionPoint(out.Position);
                      cbInfo.OutputName = out.Name;

                      if (out.InternalBelt) {
                        cbInfo.RelatedInternalBelt = out.InternalBelt.Name;
                        cbInfo.PositionRelatedInternalBelt = out.InternalBelt.Position;
                      }

                      if (out.Restricted) {
                        for (const r of out.Restricted) {
                          cbInfo.RestrictedConnections.push(this.StringToConnectionPoint(r));
                        }
                      }

                      if (out.ConveyorTypes) {
                        for (const at of out.ConveyorTypes) {

                          const tp = this.ConveyorTypes.find(ex => ex.Type == at);
                          if (tp) {
                            cbInfo.AllowedConveyorBeltTypes.push(this.ConveyorTypes.find(ex => ex.Type == at));
                            // console.log(cbInfo.AllowedConveyorBeltTypes);
                          }
                        }
                      }

                      mdl.ConveyorBelts.push(cbInfo);

                    }
                  }

                  if (nfo.Components) {
                    for (const cp of nfo.Components) {
                      if (cp.name) {
                        const comp = mdl.Components.find(ex => ex.PlcKey === cp.name);
                        if (comp) {
                          comp.Width = cp.width;
                          comp.Height = cp.height;
                          comp.Top = cp.top;
                          comp.Left = cp.left;
                          comp.Path = cp.path;
                          if (cp.connected) {
                            comp.Connections.push(this.StringToConnectionPoint(cp.connected));
                          }
                          // if (cp.messages) {
                          //   comp.Errors = [];
                          //   comp.Warnings = [];
                          //
                          //   for (const m of cp.messages) {
                          //     const neError = new NotificationEntry(mdl.Type + '-' + mdl.Version, comp.PlcKey, m, 'Error');
                          //     neError.ComponentTranslateId = comp.GetTranslationId();
                          //     neError.TextNumber = 'F' + m.toString();
                          //     const neWarning = new NotificationEntry(mdl.Type + '-' + mdl.Version, comp.PlcKey, m, 'Warning');
                          //     neWarning.ComponentTranslateId = comp.GetTranslationId();
                          //     neWarning.TextNumber = 'W' + m.toString();
                          //     comp.Errors.push(neError);
                          //     comp.Warnings.push(neWarning);
                          //   }
                          // }
                        }
                      }
                    }
                  }

                  if (nfo.tasks) {
                    for (const moduleTask of nfo.tasks) {
                      const task = new SetupTask();
                      task.Code = moduleTask.task.code;
                      task.DefaultText = moduleTask.task.text;
                      task.CodeText = moduleTask.task.codeText;
                      task.Text = moduleTask.task.text;

                      if (moduleTask.task.prefix) {
                        const prefix = moduleTask.task.prefix;
                        const mdprefix = new SetupTaskParameter();
                        mdprefix.component = prefix.component;
                        mdprefix.parameter = prefix.parameter;
                        task.Prefix = mdprefix;
                      }
                      if (moduleTask.task.surfix) {
                        const surfix = moduleTask.task.surfix;
                        const mdsurfix = new SetupTaskParameter();
                        mdsurfix.component = surfix.component;
                        mdsurfix.parameter = surfix.parameter;
                        task.Surfix = mdsurfix;
                      }
                      mdl.SetupTasks.push(task);


                    }
                  }
                }
              }
            }
          }
          this.Loaded = true;
          console.log(this.Modules);
          this.OnModulesLoaded.emit();

        }, error => {
          console.log(error);
          this.OnModulesLoadedError.emit();
        });
      }, error => {
        console.log(error);
        this.OnModulesLoadedError.emit();
      });
    } catch (e) {
      if (lokal) {
        console.log(e);
        this.OnModulesLoadedError.emit();
      } else {
        this.httpClient.get('/assets/dms/bluectrl-spec-gen-latest.json').subscribe(data => {
          const json_str = JSON.stringify(data);
          const json = JSON.parse(json_str);

          this.LoadModules(json, true);
        }, error => {
          console.log(error);
          this.OnModulesLoadedError.emit();
        });
      }
    }


  }

  public LoadPopupNotofcations(): any[] {
    const result = [];

    this.httpClient.get('assets/dms/notifications.json').subscribe(data => {
      const json_str = JSON.stringify(data);
      const json = JSON.parse(json_str);
      if (json.length > 0) {
        const currentLang = 'de';

        this.httpClient.get('assets/dms/LanguageFiles/module_text_' + currentLang + '.json').subscribe(txt => {
          const txt_str = JSON.stringify(txt);
          const txtInfo = JSON.parse(txt_str);

          const languageEntries: LanguageEntry[] = [];

          for (const k of Object.keys(txtInfo)) {
            const langEntr = new LanguageEntry();
            langEntr.Key = k;
            langEntr.Value = txtInfo[k];
            languageEntries.push(langEntr);
          }
          for (const nf of json) {
            const rl = languageEntries.find(ex => ex.Key === nf);
            if (rl) {
              result.push({
                code: nf,
                text: rl.Value
              });
            }
          }
        });
      }
    });


    return result;
  }

  public StringToConnectionPoint(position: string): ModuleConnections {
    switch (position) {
      case 'center': {
        return ModuleConnections.center;
      }
      case 'left': {
        return ModuleConnections.left;
      }
      case 'left_1': {
        return ModuleConnections.left_1;
      }
      case 'left_2': {
        return ModuleConnections.left_2;
      }
      case 'left_3': {
        return ModuleConnections.left_3;
      }
      case 'right': {
        return ModuleConnections.right;
      }
      case 'right_1': {
        return ModuleConnections.right_1;
      }
      case 'right_2': {
        return ModuleConnections.right_2;
      }
      case 'right_3': {
        return ModuleConnections.right_3;
      }
      default: {
        return ModuleConnections.none;
      }
    }
  }

  public GetModule(key: string): ModuleDefinition {
    return this.Modules.find(ex => ex.Key === key);
  }

  public GetModuleByModuleType(moduleType: string): ModuleDefinition {
    return this.Modules.find(ex => ex.Key === moduleType);
  }

  public async LoadCustomerModules(): Promise<CustomerModule[]> {
    return this.GetCustomerModulesFromServer();
  }

  public GetCurrentCustomerModules(): CustomerModule[] {
    if (this.Loaded === true) {
      if (this.CustomerModules && this.CustomerModules.length > 0) {
        return this.CustomerModules;
      }
    }

    return null;
  }

  public async GetCustomerModulesFromServer(): Promise<CustomerModule[]> {
    // const mds = CustomerModuleMock.filter(ex => ex.customer === customerId);
    const cmds: CustomerModule[] = [];

    if (this.connectivity.inOfflineMode) {

      console.log('offline modules');

      const datamds = await this.database.getCustomerModules(this.usermanagement.currentUser.CustomerId).then(data => data);
      if (datamds) {
        const mds = JSON.parse(datamds.data);
        for (const md of mds) {
          const productId = md.serial.substr(0, md.serial.length - 5);
          const module = this.GetModule(productId);
          if (module !== undefined && module !== null) {
            const modl = module.Copy();
            modl.SetId();
            const customerModule = new CustomerModule(this.usermanagement.currentUser.CustomerId, md.serial, modl);
            customerModule.SetId();
            if (md.owner) {
              customerModule.Owner = md.owner.id;
              customerModule.OwnerName = md.owner.name;
              customerModule.Ward = new Wards(md.owner.id, md.owner.name);
            }
            if (md.holder) {
              customerModule.Holder = md.holder.id;
              customerModule.HolderName = md.holder.name;
            }
            cmds.push(customerModule);
          }
        }

        for (const wrd of this.usermanagement.currentUser.UserWards) {
          const mdsWardData = await this.database.getCustomerModules(wrd.Id).then(data => data);

          if (mdsWardData) {
            const mdsWard = JSON.parse(mdsWardData.data);
            if (mdsWard.length > 0) {
              for (const md of mdsWard) {
                const productId = md.serial.substr(0, md.serial.length - 5);

                const module = this.GetModule(productId);
                if (module !== undefined && module !== null) {
                  const modl = module.Copy();
                  modl.SetId();
                  const customerModule = new CustomerModule(wrd.Id, md.serial, modl);
                  customerModule.SetId();
                  if (md.owner) {
                    customerModule.Owner = md.owner.id;
                    customerModule.OwnerName = md.owner.name;
                  }
                  if (md.holder) {
                    customerModule.Holder = md.holder.id;
                    customerModule.HolderName = md.holder.name;
                  }
                  customerModule.Ward = new Wards(wrd.Id, wrd.Name);

                  cmds.push(customerModule);
                }
              }
            }
          }
        }

      }
    } else {


      const mds = await this.apiService.GetUserModules(null)
        .toPromise()
        .then(data => data, () => {
          this.CustomerModulesLoadedError.emit();
          return null;
        });


      for (const md of mds) {
        const productId = md.serial.substr(0, md.serial.length - 5);
        const module = this.GetModule(productId);
        if (module !== undefined && module !== null) {
          const modl = module.Copy();
          modl.SetId();
          const customerModule = new CustomerModule(this.usermanagement.currentUser.CustomerId, md.serial, modl);
          customerModule.SetId();
          if (md.owner) {
            customerModule.Owner = md.owner.id;
            customerModule.OwnerName = md.owner.name;
            customerModule.Ward = new Wards(md.owner.id, md.owner.name);
          }
          if (md.holder) {
            customerModule.Holder = md.holder.id;
            customerModule.HolderName = md.holder.name;
          }
          cmds.push(customerModule);
        }
      }


      // FOR EACH WARD

      if (this.usermanagement.currentUser) {


        for (const wrd of this.usermanagement.currentUser.UserWards) {
          const mdsWard = await this.apiService.GetUserModules(wrd.Id)
            .toPromise()
            .then(datas => datas, () => {
              this.CustomerModulesLoadedError.emit();
              return null;
            });


          if (mdsWard.length > 0) {
            for (const md of mdsWard) {
              const productId = md.serial.substr(0, md.serial.length - 5);

              const module = this.GetModule(productId);
              if (module !== undefined && module !== null) {
                const modl = module.Copy();
                modl.SetId();
                const customerModule = new CustomerModule(wrd.Id, md.serial, modl);
                customerModule.SetId();
                if (md.owner) {
                  customerModule.Owner = md.owner.id;
                  customerModule.OwnerName = md.owner.name;
                }
                if (md.holder) {
                  customerModule.Holder = md.holder.id;
                  customerModule.HolderName = md.holder.name;
                }
                customerModule.Ward = new Wards(wrd.Id, wrd.Name);

                cmds.push(customerModule);
              }
            }
          }

        }
      }
    }
    this.CustomerModules = cmds;
    console.log(cmds);
    return cmds;
  }

  public GetConveyors(): Observable<Conveyor[]> {
    return of(MockConveyors);
  }

  public GetConveyorByType(type: ConveyorTypes): Conveyor {
    return MockConveyors.find(ex => ex.Type === type.Type);
  }

}
